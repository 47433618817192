<template>
    <BRow>
        <BCol cols="12" class="d-flex justify-content-between">
            <div class="
              bg-light
              d-flex
              justify-content-center
              align-items-center
              p-50
              rounded
            ">
                <span class="text-black mr-50"> List per halaman: </span>
                <BButton v-for="page in optionsPage" :key="page" class="btn-icon" size="sm"
                    :variant="totalPerPage === page ? 'primary' : 'flat-dark'" @click="handleSetPerpage(page)">
                    {{ page }}
                </BButton>
            </div>

            <BPagination v-model="localCurrentPage" :total-rows="totalRows" :per-page="totalPerPage" first-number last-number
                class="pagination-primary">
                <template #prev-text>
                    <feather-icon size="18" icon="ChevronLeftIcon" />
                </template>
                <template #next-text>
                    <feather-icon size="18" icon="ChevronRightIcon" />
                </template>
            </BPagination>
        </BCol>
    </BRow>
</template>

<script>
export default {
  props: {
    optionsPage: Array,
    currentPage: Number,
    totalRows: Number,
    totalPerPage: Number,
  },
  data() {
    return {
      localCurrentPage: this.currentPage,
    }
  },
  watch: {
    localCurrentPage: {
      handler() {
        this.$emit('handleCurrentPage', this.localCurrentPage)
      },
    },
  },
  methods: {
    handleSetPerpage(data) {
      this.$emit('handlePerpage', data)
    },
  },
}

</script>
